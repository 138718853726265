<script setup>
import { mdiAlert, mdiCalendarMonth } from '@mdi/js'

const state = reactive({
  activeEvent: null,
  eventDialog: false,
})

function showDetails(event) {
  state.activeEvent = event
  state.eventDialog = true
}

const { data, pending, error } = await useAsyncData(
  'events',
  () => $fetch('/api/calendar'),
)
</script>

<template>
  <v-card
    class="eventList"
    elevation="0"
  >
    <v-list
      class="pa-0"
      border
      :role="null"
    >
      <v-list-subheader class="text-subtitle-1">
        Upcoming Events
      </v-list-subheader>
      <v-divider />
      <template v-if="pending">
        <v-list-item class="d-flex justify-center align-center text-center" style="height: 240px">
          <v-progress-circular class="mb-3" color="primary" indeterminate />
          <div>
            Loading Events
          </div>
        </v-list-item>
        <v-divider />
      </template>
      <template v-if="error">
        <v-list-item v-if="error" title="Failed to load events">
          <template #prepend>
            <v-icon class="calendarIcon" :icon="mdiAlert" />
          </template>
          <v-list-item-subtitle>
            {{ error }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-divider />
      </template>

      <template v-for="event in data" :key="event.id">
        <v-list-item
          class="py-1"
          :title="event.title"
          :subtitle="`${$dayjs(event.startDate).format('h:mm A')}${event.location ? ` - ${event.location}` : ''}`"
          @click="showDetails(event)"
        >
          <template #prepend>
            <div class="calendarAvatar mr-3">
              <div class="dayOfMonth">
                {{ $dayjs(event.startDate).format('D') }}
              </div>
              <div class="monthOfYear">
                {{ $dayjs(event.startDate).format('MMM') }}
              </div>
            </div>
          </template>
        </v-list-item>
        <v-divider />
      </template>
      <v-list-item title="View Calendar" to="/members/calendar">
        <template #prepend>
          <v-icon class="calendarIcon" :icon="mdiCalendarMonth" />
        </template>
      </v-list-item>
    </v-list>

    <EventModal
      v-model="state.eventDialog"
      :event="state.activeEvent"
    />
  </v-card>
</template>

<style scoped lang="scss">
  .calendarAvatar {
    width: 35px;
    .dayOfMonth {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      line-height: 1;
    }
    .monthOfYear {
      text-transform: uppercase;
      font-size: 12px;
      text-align: center;
      line-height: 1;
    }
  }

  .calendarIcon {
    margin-left: 5px;
    margin-right: 18px;
  }

  :deep(.v-list-item__prepend > .v-icon ~ .v-list-item__spacer) {
    width: 0;
  }
</style>
