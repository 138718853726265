
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as our_45storylhpdLNJ7TpMeta } from "/app/src/pages/about/our-story.vue?macro=true";
import { default as plan_45your_45visitMStBlkNGfhMeta } from "/app/src/pages/about/plan-your-visit.vue?macro=true";
import { default as what_45we_45believeFs5uOZ4UowMeta } from "/app/src/pages/about/what-we-believe.vue?macro=true";
import { default as contactmqHVTjI8koMeta } from "/app/src/pages/contact.vue?macro=true";
import { default as next_45stepsTqD9FMXCd0Meta } from "/app/src/pages/faith/next-steps.vue?macro=true";
import { default as what_45is_45the_45gospel4ZrWAO4xpmMeta } from "/app/src/pages/faith/what-is-the-gospel.vue?macro=true";
import { default as forgot_45passwordvHMplVNcGwMeta } from "/app/src/pages/forgot-password.vue?macro=true";
import { default as giveXfMTaAJvNRMeta } from "/app/src/pages/give.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as login3hUYZUGxmUMeta } from "/app/src/pages/login.vue?macro=true";
import { default as indexOdIwxC4zy3Meta } from "/app/src/pages/members/calendar/index.vue?macro=true";
import { default as _91id_934AXxjT5hTwMeta } from "/app/src/pages/members/collections/[id].vue?macro=true";
import { default as index5IZG4ctR23Meta } from "/app/src/pages/members/collections/index.vue?macro=true";
import { default as indexM1bokDuIOyMeta } from "/app/src/pages/members/index.vue?macro=true";
import { default as my_45accounto3W9UGEl1lMeta } from "/app/src/pages/members/my-account.vue?macro=true";
import { default as _91user_id_93nu1esK20oJMeta } from "/app/src/pages/members/users/[user_id].vue?macro=true";
import { default as indexa5DIx1ZsNWMeta } from "/app/src/pages/members/users/index.vue?macro=true";
import { default as userswS2kYU6BImMeta } from "/app/src/pages/members/users.vue?macro=true";
import { default as children6aqS5x1A3zMeta } from "/app/src/pages/ministries/children.vue?macro=true";
import { default as day_45camp0Nqnhq28MXMeta } from "/app/src/pages/ministries/day-camp.vue?macro=true";
import { default as discipleshipfw8gznluRIMeta } from "/app/src/pages/ministries/discipleship.vue?macro=true";
import { default as youthoIfVutbTfuMeta } from "/app/src/pages/ministries/youth.vue?macro=true";
import { default as password_45reset0j1NLDK5h5Meta } from "/app/src/pages/password-reset.vue?macro=true";
import { default as privacy3wcvBua6OkMeta } from "/app/src/pages/privacy.vue?macro=true";
export default [
  {
    name: "about-our-story",
    path: "/about/our-story",
    component: () => import("/app/src/pages/about/our-story.vue")
  },
  {
    name: "about-plan-your-visit",
    path: "/about/plan-your-visit",
    component: () => import("/app/src/pages/about/plan-your-visit.vue")
  },
  {
    name: "about-what-we-believe",
    path: "/about/what-we-believe",
    component: () => import("/app/src/pages/about/what-we-believe.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/app/src/pages/contact.vue")
  },
  {
    name: "faith-next-steps",
    path: "/faith/next-steps",
    component: () => import("/app/src/pages/faith/next-steps.vue")
  },
  {
    name: "faith-what-is-the-gospel",
    path: "/faith/what-is-the-gospel",
    component: () => import("/app/src/pages/faith/what-is-the-gospel.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/app/src/pages/forgot-password.vue")
  },
  {
    name: "give",
    path: "/give",
    component: () => import("/app/src/pages/give.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/src/pages/login.vue")
  },
  {
    name: "members-calendar",
    path: "/members/calendar",
    meta: indexOdIwxC4zy3Meta || {},
    component: () => import("/app/src/pages/members/calendar/index.vue")
  },
  {
    name: "members-collections-id",
    path: "/members/collections/:id()",
    meta: _91id_934AXxjT5hTwMeta || {},
    component: () => import("/app/src/pages/members/collections/[id].vue")
  },
  {
    name: "members-collections",
    path: "/members/collections",
    meta: index5IZG4ctR23Meta || {},
    component: () => import("/app/src/pages/members/collections/index.vue")
  },
  {
    name: "members",
    path: "/members",
    meta: indexM1bokDuIOyMeta || {},
    component: () => import("/app/src/pages/members/index.vue")
  },
  {
    name: "members-my-account",
    path: "/members/my-account",
    meta: my_45accounto3W9UGEl1lMeta || {},
    component: () => import("/app/src/pages/members/my-account.vue")
  },
  {
    name: userswS2kYU6BImMeta?.name,
    path: "/members/users",
    component: () => import("/app/src/pages/members/users.vue"),
    children: [
  {
    name: "members-users-user_id",
    path: ":user_id()",
    meta: _91user_id_93nu1esK20oJMeta || {},
    component: () => import("/app/src/pages/members/users/[user_id].vue")
  },
  {
    name: "members-users",
    path: "",
    meta: indexa5DIx1ZsNWMeta || {},
    component: () => import("/app/src/pages/members/users/index.vue")
  }
]
  },
  {
    name: "ministries-children",
    path: "/ministries/children",
    component: () => import("/app/src/pages/ministries/children.vue")
  },
  {
    name: "ministries-day-camp",
    path: "/ministries/day-camp",
    component: () => import("/app/src/pages/ministries/day-camp.vue")
  },
  {
    name: "ministries-discipleship",
    path: "/ministries/discipleship",
    component: () => import("/app/src/pages/ministries/discipleship.vue")
  },
  {
    name: "ministries-youth",
    path: "/ministries/youth",
    component: () => import("/app/src/pages/ministries/youth.vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    component: () => import("/app/src/pages/password-reset.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/app/src/pages/privacy.vue")
  }
]