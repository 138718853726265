import { mdiAlert, mdiBug, mdiInformationOutline, mdiThumbUp } from '@mdi/js'

// Usage:
// const nuxtApp = useNuxtApp();
// const isConfirmed = await nuxtApp.$confirm.warning('Title', 'Message');
export default defineNuxtPlugin((nuxtApp) => {
  // import the confirm store
  const { state } = useConfirmDialogStore()

  // define confirm helpers
  const confirm = {
    // show a confirmation modal
    show({ icon, title, message, width, color }) {
      state.icon = icon
      state.title = title
      state.message = message
      state.width = width
      state.color = color
      state.visible = true
      return new Promise((resolve) => {
        state.resolve = resolve
      })
    },

    // show an info confirmation modal
    info(title, message) {
      return this.show({
        title,
        message,
        color: 'info',
        icon: mdiInformationOutline,
      })
    },

    // show a success confirmation modal
    success(title, message) {
      return this.show({
        title,
        message,
        color: 'success',
        icon: mdiThumbUp,
      })
    },

    // show a warning confirmation modal
    warning(title, message) {
      return this.show({
        title,
        message,
        color: 'warning',
        icon: mdiAlert,
      })
    },

    // show an error confirmation modal
    error(title, message) {
      return this.show({
        title,
        message,
        color: 'error',
        icon: mdiBug,
      })
    },
  }

  // provide confirm helpers
  nuxtApp.provide('confirm', confirm)
})
