<script setup>
import { mdiAlert, mdiYoutube } from '@mdi/js'

import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/style.css'

const { data, error } = await useFetch('/api/videos/latest')
</script>

<template>
  <div class="wrapper">
    <v-empty-state
      v-if="error"
      title="Failed to Load Latest Video"
      :text="error.data.message"
      :icon="mdiAlert"
      color="error"
    >
      <v-btn
        color="error"
        variant="outlined"
        :prepend-icon="mdiYoutube"
        href="https://www.youtube.com/@PathwayBibleChurch"
        target="_blank"
      >
        View on YouTube
      </v-btn>
    </v-empty-state>

    <template v-else>
      <LiteYouTubeEmbed
        :id="data.video_id"
        :title="data.title"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;

  .loader,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
