<script setup>
import { mdiEmailOutline } from '@mdi/js'


const config = useRuntimeConfig()
const recaptcha = useRecaptcha()

const state = reactive({
  loading: false,
  valid: false,
  email: null,
  first_name: null,
  last_name: null,
  success: false,
  error: false,
  errorMsg: null,
})

const form = ref()

async function submit() {
  form.value.validate()
  if (!state.valid)
    return

  state.loading = true
  try {
    // run recaptcha
    const token = await recaptcha('subscribe')

    await $fetch('/api/newsletter/subscribe', {
      method: 'POST',
      body: {
        recaptcha_token: token,
        email: state.email,
        first_name: state.first_name,
        last_name: state.last_name,
        listId: config.public.mailchimp.audienceId,
      },
    })

    state.success = true
    setTimeout(() => {
      state.success = false
    }, 5000)
  }
  catch (err) {
    console.error(err?.response)
    state.errorMsg = err?.response?._data?.message || 'Something went wrong. Please try again.'
    state.error = true
    setTimeout(() => {
      state.error = false
    }, 5000)
  }
  finally {
    state.loading = false
    form.value.reset()
  }
}
</script>

<template>
  <div>
    <v-form
      ref="form"
      v-model="state.valid"
      :disabled="state.loading"
      @submit.prevent="submit"
    >
      <v-row dense justify="center" align="center">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="state.first_name"
            label="First Name"
            density="compact"
            hide-details="auto"
            :rules="[
              v => !!v || 'Required',
            ]"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="state.last_name"
            label="Last Name"
            density="compact"
            hide-details="auto"
            :rules="[
              v => !!v || 'Required',
            ]"
          />
        </v-col>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="state.email"
            label="Email Address"
            type="email"
            density="compact"
            hide-details="auto"
            :rules="[
              v => !!v || 'Required',
              v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid E-mail Address',
            ]"
          />
        </v-col>
        <v-col cols="12" md="4">
                    <v-btn
            color="primary"
            style="text-transform: none;"
            :disabled="state.loading"
            :loading="state.loading"
            type="submit"
            :prepend-icon="mdiEmailOutline"
            block
          >
            Sign Up!
          </v-btn>
        </v-col>
        <v-col cols="12" class="text-center">
          <div class="text-center text-caption mb-1">
            Unsubscribe at any time. Your information will never be shared or sold.
          </div>
        </v-col>
      </v-row>

      <v-alert
        v-model="state.success"
        type="success"
        density="comfortable"
        dismissible
        transition="slide-y-transition"
      >
        Thank you for subscribing!
      </v-alert>
      <v-alert
        v-model="state.error"
        type="error"
        density="comfortable"
        dismissible
        transition="slide-y-transition"
      >
        {{ state.errorMsg }}
      </v-alert>
    </v-form>
  </div>
</template>

<style scoped>
  .form {
    display: flex;
  }
  .form .v-input {
    margin-right: 15px;
  }
  @media (max-width: 420px) {
    .form {
      display: block;
    }
    .form .v-input {
      margin-right: 0px;
    }
    .form .v-btn {
      width: 100%;
    }
  }
</style>
