import { mdiChatQuestion } from '@mdi/js'

export const useConfirmDialogStore = defineStore('confirmDialogStore', () => {
  const state = reactive({
    visible: false,
    icon:  mdiChatQuestion,
    title: 'Are you sure?',
    message: 'Are you sure that you want to perform this action?',
    width: 650,
    color: 'info',
    resolve: null,
  })

  // actions
  const onConfirm = () => {
    state.resolve(true)
    state.visible = false
  }

  const onCancel = () => {
    state.resolve(false)
    state.visible = false
  }

  // return all the things
  return {
    state,
    onConfirm,
    onCancel,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useConfirmDialogStore, import.meta.hot))
