<script setup>
import { mdiAccountGroupOutline } from '@mdi/js'


const { data } = useFetch('/api/users/pending-review')

</script>

<template>
  <v-list-item
    :prepend-icon="mdiAccountGroupOutline"
    title="Users"
    to="/members/users"
  >
    <template
      #append
      v-if="data?.length"
    >
      <v-badge
        color="error"
        :content="data.length"
        max="9"
        inline
      />
    </template>
  </v-list-item>
</template>
